.ui-g-w-xs {
  display: flex;
  margin-left: -4px;
  margin-top: -4px;
}

.ui-g-xs {
  display: flex;
  margin-left: 4px;
  margin-top: 4px;
}

.ui-g-w-sm {
  display: flex;
  margin-left: -8px;
  margin-top: -8px;
}

.ui-g-sm {
  display: flex;
  margin-left: 8px;
  margin-top: 8px;
}
.ui-g-w-md {
  display: flex;
  margin-left: -16px;
  margin-top: -16px;
}

.ui-g-md {
  display: flex;
  margin-left: 16px;
  margin-top: 16px;
}

.ui-g-md-p {
  display: flex;
  padding-left: 16px;
  padding-top: 16px;
}

.ui-m-xs-t {
  margin-top: 4px;
}

.ui-m-xs-r {
  margin-right: 4px;
}

.ui-m-xs-b {
  margin-bottom: 4px;
}

.ui-m-xs-l {
  margin-left: 4px;
}

.ui-m-sm-t {
  margin-top: 8px;
}

.ui-m-sm-r {
  margin-right: 8px;
}

.ui-m-sm-b {
  margin-bottom: 8px;
}

.ui-m-sm-l {
  margin-left: 8px;
}

.ui-m-md-t {
  margin-top: 12px;
}

.ui-m-md-r {
  margin-right: 12px;
}

.ui-m-md-b {
  margin-bottom: 12px;
}

.ui-m-md-l {
  margin-left: 12px;
}

.ui-m-lg-t {
  margin-top: 16px;
}

.ui-m-lg-r {
  margin-right: 16px;
}

.ui-m-lg-b {
  margin-bottom: 16px;
}

.ui-m-lg-l {
  margin-left: 16px;
}

.ui-m-xl-t {
  margin-top: 20px;
}

.ui-m-xl-r {
  margin-right: 20px;
}

.ui-m-xl-b {
  margin-bottom: 20px;
}

.ui-m-xl-l {
  margin-left: 20px;
}

.ui-m-xxl-t {
  margin-top: 32px;
}

.ui-p-xxl-r {
  margin-right: 32px;
}

.ui-m-xxl-b {
  margin-bottom: 32px;
}

.ui-p-xxl-l {
  margin-left: 32px;
}

.ui-p-xs-t {
  padding-top: 4px;
}

.ui-p-xs-r {
  padding-right: 4px;
}

.ui-p-xs-b {
  padding-bottom: 4px;
}

.ui-p-xs-l {
  padding-left: 4px;
}

.ui-p-sm {
  padding: 8px;
}

.ui-p-sm-t {
  padding-top: 8px;
}

.ui-p-sm-r {
  padding-right: 8px;
}

.ui-p-sm-b {
  padding-bottom: 8px;
}

.ui-p-sm-l {
  padding-left: 8px;
}

.ui-p-md-t {
  padding-top: 12px;
}

.ui-p-md-r {
  padding-right: 12px;
}

.ui-p-md-b {
  padding-bottom: 12px;
}

.ui-p-md-l {
  padding-left: 12px;
}

.ui-p-lg-t {
  padding-top: 16px;
}

.ui-p-lg-r {
  padding-right: 16px;
}

.ui-p-lg-b {
  padding-bottom: 16px;
}

.ui-p-lg-l {
  padding-left: 16px;
}

.ui-p-xl-t {
  padding-top: 20px;
}

.ui-p-xl-r {
  padding-right: 20px;
}

.ui-p-xl-b {
  padding-bottom: 20px;
}

.ui-p-xl-l {
  padding-left: 20px;
}

.ui-p-xxl-t {
  padding-top: 32px;
}
.ui-p-xxl-b {
  padding-bottom: 32px;
}
.ui-p-xxl-l {
  padding-left: 32px;
}
.ui-p-xxl-r {
  padding-right: 32px;
}

.ui-3 {
  width: 25%;
}

.ui-6 {
  width: 50%;
}

.ui-12 {
  width: 100%;
}

.ui-d-flex {
  display: flex;
}

.ui-ai-center {
  align-items: center;
}

.ui-ai-start {
  align-items: flex-start;
}

.ui-jc-center {
  justify-content: center;
}

.ui-jc-space-between {
  justify-content: space-between;
}

.ui-fd-column {
  flex-direction: column;
}
.ui-fw-wrap {
  flex-wrap: wrap;
}

.ui-fg-0 {
  flex-grow: 0;
}

.ui-fg-1 {
  flex-grow: 1;
}

.ui-d-block {
  display: block;
}

.transition{
  transition: all .3s ease 0s;
}

b{
  font-weight: 700;
}