*{
    box-sizing: border-box;
}

body{
    line-height: 1;
    font-family: Inter, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    min-width: 1133px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--color-dark6);

}

#app section{
    font-weight: 400;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: hidden;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-dark3) !important;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-dark3) !important;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--color-dark3) !important;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
}
